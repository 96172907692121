<template>
    <div class="consultingService_wrap">
        <Head :centerInfo="true"></Head>
        <!-- <div class="mauto"> -->
            <div class="consultingService_content">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="资讯内容" name="1"></el-tab-pane>
                    </el-tabs>
                    <div>
                        <p style="text-align:center">{{listData.title}}</p>
                        <div v-html="listData.content"></div>
                    </div>
            </div>
            <div class="consultingService_jump">
                    <el-tabs v-model="activeName1" width="100%" @tab-click="handleClick">
                        <el-tab-pane label="联众产品推荐" name="1"></el-tab-pane>
                </el-tabs>
                <div>
                        <a href="http://newmall.ourgame.com/product.html?temId=79&title=%E5%A4%A7%E5%8E%85%E7%A4%BC%E7%89%A9%20&classId=141" target="_blank"> <img src="~assets/news1_icon.png" alt=""> </a>
                        <a href="http://newmall.ourgame.com/product.html?temId=71&title=%E6%B8%B8%E4%B9%90%E8%B1%86%20&classId=133" target="_blank"> <img src="~assets/news2_icon.png" alt=""> </a>
                        <a href="http://newmall.ourgame.com/product.html?temId=69&title=%E8%81%94%E4%BC%97%E4%BC%9A%E5%91%98%20&classId=131" target="_blank"> <img src="~assets/news3_icon.png" alt=""> </a>
                        <a href="http://newmall.ourgame.com/product.html?temId=70&title=%E8%81%94%E4%BC%97%E6%A0%87%E5%BF%97%20&classId=132" target="_blank"> <img src="~assets/news4_icon.png" alt=""> </a>
                        <a href="http://newmall.ourgame.com/" target="_blank"> <img src="~assets/news5_icon.png" alt=""> </a>
                </div>
                
            </div>
        </div>
    <!-- </div> -->
</template>

<script>
import Head from '@/components/head.vue';
  import { getConfigNoticeDetail } from '@/api/authority'
export default {
  name: "consultingService",
  data() {
    return {
      activeName: '1',
      activeName1:"1",
      listData:{}
    };
  },
  components:{
      Head
  },
  mounted(){
      this.getList()
      window.scrollTo(0,0)
  },
  methods: {
    getList(){
        getConfigNoticeDetail({noticeID:this.$route.query.id}).then(response => {
            if(response.data.code == 0){
                this.listData = response.data.data
            }else{
                this.$message({
                'message': response.data.msg
                })
            }
        })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>
<style lang="less">
    .consultingService_content{
        width: 1281px;
        height: 660px!important;
        background-color: #ffffff;
        border: solid 1px #eeeeee;
        padding:40px;
        overflow: auto;
        margin:0px auto;
        margin-top:105px;
        box-sizing: border-box;
    }
    .consultingService_jump{
        margin-left:17px;
        padding-bottom: 60px;
               margin:0px auto;
        margin-top:40px;
             width: 1281px;
        overflow: hidden;
        a{
            display: block;
            float:left;
            margin-top:10px;
            img{
                	width: 186px;
	                height: 77px;
            }
        }
        a+a{
            margin-left:17px;
        }
    }
</style>